module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Metric Soup","short_name":"Metric Soup","start_url":"/","background_color":"#ffffff","theme_color":"#bdc9bd","display":"standalone","icon":"src/assets/Logo - Favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"396b38c4694cfdff9b1f09bfa4989f90"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
